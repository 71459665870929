/** @jsx jsx */
import { Image, jsx } from "theme-ui";

import { Layout, Content, Section } from "maker-ui";

import React, { useCallback, useEffect, useState } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ReactElasticCarousel from "react-elastic-carousel";
import HtmlDiv from "sites-common/components/HtmlDiv";
import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import HeaderComp from "../components/Header";
import FooterComp from "../components/Footer";
import Seo from "../components/seo";
// import sliderBg from "../../static/imgs/every-drop-count-slider_bg.png";
import bannerImg from "../../static/imgs/every-drop-count-banner.png";
import everyDropCountBox from "../../static/imgs/everyDropCountBox.jpg";
import children from "../../static/imgs/Children.jpg";
import cons from "../../static/imgs/cons.jpg";
import sports from "../../static/imgs/sports.png";
import excellence from "../../static/imgs/excellence.jpg";

import "../../static/styles/everyDropCount.css";
// import EveryDropCountsSlider from "../components/EveryDropCountsSlider";
import { eventCallbackTriggers } from "../templates/wppage";
import EcsFormsDownload from "../components/EcsFormsDownload";

require("react-datepicker/dist/react-datepicker.css");
require("bootstrap/dist/css/bootstrap.min.css");

library.add(faTimes, faUserPlus);

const EveryDropCountsSmsf = () => {
  const [showDialog, setShowDialog] = useState(false);
  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("every-drop-counts", event),
    []
  );
  const [familyMembers, setFamilyMembers] = useState([
    {
      value: "",
      index: 1,
      error: "",
    },
  ]);
  const [amount, setAmount] = useState(50);
  const [customAmount, setCustomAmount] = useState("");
  const [err, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [flag, setFlag] = useState(false);
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    if (familyMembers.length > 0) {
      const members = familyMembers?.filter((i) => i?.value && !i?.error);
      const enableAddMember = familyMembers?.every(
        (i) => i?.value && !i?.error
      );
      setEnable(!enableAddMember);
      if ((amount || (customAmount && !err)) && members?.length) {
        setErrorMessage("");
        setFlag(true);
      } else {
        setFlag(false);
        setErrorMessage("Please add atleast one member and a valid amount.");
      }
    }
  }, [amount, customAmount, err, familyMembers]);

  const pageContent = () => {
    let donationAmount = 0;
    const members = [];
    familyMembers
      ?.filter((i) => i?.value && !i?.error)
      ?.map((i) => {
        return members.push(i?.value);
      });

    if (amount || (customAmount && customAmount > 0)) {
      const len = members?.length;
      donationAmount = (amount || customAmount) * len * 30;
    }

    return (
      `<gatsby_donation amount="${donationAmount}" donationid="27" btntext="Donate ${
        donationAmount ? `- ₹${donationAmount.toFixed(2)}` : ""
      } Monthly" familyMembers="${members?.join(",")}"` +
      'projectid="" btnbg="#4d8f16" colortext="black" colorprimary="forestgreen"' +
      'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
      'colorhighlight="#efeffe" titletext="Every Drop Counts - Donation, Recurring" isrecurring="true"/>'
    );
  };
  const PageContent = React.memo(PageContentNonMemoized);

  const handleDownload = () => {
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
  };

  const data = [
    {
      image: cons,
      heading: "Environment",
      desc: `<p style="font-size: 18px">
  With each tree holding the grace that is showered upon it, rejuvenating and creating ecosystems through Forest by Heartfulness has led to plantations in 10,000+ acres across India with our vast network of nurseries and Tissue Culture practices. Agroforestry, tissue culture saplings and Water rejuvenation techniques work hand in hand to aid every community that we work with.
</p>
<h5>Additional Facts</h5>
<ul>
  <li>4 million+ saplings already planted</li>
  <li>20,000+ Local farmers, MSMEs, Indigenous Community Members Supported</li>
  <li>25,000 TCO2 Estimated annual carbon offsets of implemented projects</li>
  <li>10000 acres+ Land afforested</li>
  <li>40 Projects</li>
  <li>4 Lakes Restored</li>
</ul>

`,
    },
    {
      image: sports,
      heading: "Enhancement",
      desc: `
      <p>As a cradle of ayurvedic and naturopathy, Wellness by Heartfulness and our Sports Excellence Programs are an offering that allows the community to achieve a higher level of physical enablement and achievement while being sustained by supporting infrastructure designed for the long run.
</p>
<h5>Additional Facts</h5>
<ul>
      <li><b>Voice that Cares</b> - 26,000+ Counselled Psychosocial First Aid  and 6,000+ Hours Of PSFA counselling for Mental Health
      </li>
      <li><b>Divya Janani</b> : Women and Child Development - 100,000+ Asha & Aanganwadi Workers</li>
      <li><b>7 Manobal Centers</b> (planned) For deaddiction & relapse prevention</li>
      <li><b>HELP</b> Total Students Covered  1,000,000+
      </li>
      <li>A Heartfulness International Sports Village</li>
      <li>172-acre biophilic campus to house sports and wellness village first of its kind in MP</li>
</ul>
      `,
    },
    {
      image: children,
      heading: "Education",
      desc: `
<p>
In this dynamic age of technological and value shifts, it is crucial to place the tools that allow children to radiate love and be in sync with their intuition. Through ventures in schools and universities we reach out to every level of the system, from the child to their teachers to their parents with our programs aimed at enhancing the crucial aspects.</p>
<h5>Additional Facts</h5>
<ul>
      <li><b>Heartful Schools</b> - Reached 25.0000 schools | 9.5 lakh students |
            50,000 teachers to date
      </li>
      <li><b>Heartful Campus</b> - Reached 1500 colleges</li>
      <li>Enrolled in the <b>Brighter Minds for Cognitive Development</b> - 100,000+ Children</li>
      <li>Enrolled and participated in <b>Heartful Campus programmes</b> - 7,00,000 Students
      </li>
      <li>Enrolled and participated across <b>INSPIRE and Heartful Campus Programmes</b> - 18,000 College Faculty
      </li>
      <li><b>INSPIRE: Trained as Heartful Teachers</b> - 50,000+ Teachers</li>
</ul>
`,
    },
    {
      image: excellence,
      heading: "Excellence",
      desc: `
      <p>It is fulfilling, both for the individual and the environment, when he is embodying his highest self. By exposing and engaging the willing in meditation, we conduct Mahotsavs from the local to the international level to help each individual in their journey to move forward towards their destiny. This also manifests through the facilities present in our current and upcoming ashrams to support the seekers as they pursue the highest state.</p>
      <h5>Additional Facts</h5>
<ul>
      <li>6000 Meditation Spots</li>
      <li>280+ Retreat Centers</li>
      <li>160+ Countries</li>
      <li><b>Global Spirituality Mahotsav</b> - Over 100,000 Seekers participated in the event</li>
</ul>
      `,
    },
  ];

  const handleInputChange = (index, event) => {
    const list = [...familyMembers];
    const obj = list?.find((i) => i?.index === index) || {};
    const pattern =
      /^[^ <>'\\"/;^`~_=+|?!@#$%^&*()[\]{}\d,.:][^<>'\\"/;^`~_=+|?!@#$%^&*()[\]{}\d,.:]{0,150}$/;
    if (
      Object.keys(obj).length > 0 &&
      event?.target?.value &&
      pattern.test(event?.target?.value)
    ) {
      obj.value = event?.target?.value;
      obj.error = "";
    } else {
      obj.value = event?.target?.value;
      obj.error = "Please enter a valid name.";
    }
    setFamilyMembers(list);
  };

  const handleAddField = () => {
    if (familyMembers?.length !== 10) {
      const obj = {
        value: "",
        index: familyMembers.slice(-1)[0]?.index + 1,
      };
      setFamilyMembers((prev) => [...prev, obj]);
    }
  };

  const removeMember = (index) => {
    const list = familyMembers?.filter((i) => i?.index !== index);
    setFamilyMembers(list);
  };
  const defaultAmounts = [50, 100, 200, 300];
  // const breakPoints = [
  //   { width: 400, itemsToShow: 1, pagination: false },
  //   { width: 600, itemsToShow: 2, pagination: false },
  //   { width: 800, itemsToShow: 3, pagination: false },
  // ];
  return (
    <Layout theme={theme} options={options}>
      <Seo title="Recurring-India" />
      <HeaderComp isDonateBtn />
      <Content>
        <Section>
          <Image sx={{ backgroundColor: "green" }} src={bannerImg} />
          <div className="bgImg">
            <div className="container">
              <div className="row">
                <div
                  sx={{
                    textAlign: "center",
                    mt: ["2rem", "2rem", "5rem"],
                    mb: "2rem",
                  }}
                >
                  <p
                    sx={{
                      fontSize: ["30px", "30px", "50px"],
                      fontWeight: 600,
                      mb: 0,
                    }}
                  >
                    Daaji&apos;s Vision
                  </p>
                </div>
                <div className="col-lg-9">
                  <p className="daajiVision">
                    Heartfulness is committed to fostering a culture of
                    compassion, mindfulness, and holistic living. Daaji
                    envisions a world resonating with inner peace, wellness, and
                    holistic living. From meditation programs and wellness
                    workshops to educational outreach and environmental
                    sustainability efforts, the Heartfulness Movement is
                    expanding all across the globe at a rapid pace, with
                    dedicated hearts joining in in making initiatives available
                    to all. These initiatives, inspired by Daaji&apos;s vision,
                    are designed to uplift individuals and communities, helping
                    them achieve balance and harmony in today&apos;s fast-paced
                    world.
                  </p>
                </div>
                <div className="col-lg-3 px-1 text-center">
                  <Image src={everyDropCountBox} sx={{ width: "98%" }} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="container"
            sx={{
              color: "#2b2b2b",
              fontSize: "18px",
              fontFamily:
                '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji',
            }}
          >
            <div className="col-12">
              <div sx={{ textAlign: "center", mt: ["2rem", "2rem", "5rem"] }}>
                <p
                  sx={{
                    fontSize: ["30px", "30px", "75px"],
                    fontWeight: "bolder",
                    mb: 0,
                  }}
                >
                  Every Drop Counts
                </p>
                <p
                  sx={{
                    mb: 4,
                    fontSize: ["15px", "15px", "35px"],
                    color: "#2E9819",
                    fontFamily: "home-made-apple",
                  }}
                >
                  a golden opportunity
                </p>
              </div>

              <p className="mb-4">
                The success and expansion of these efforts rely heavily on the
                ongoing support and contributions of our dedicated members. Your
                voluntary contributions of time, resources or finances are
                essential in sustaining and growing these programs. They enable
                us to reach more people, enhance the quality of our offerings,
                and continue to make a positive impact on society.
              </p>
              <p className="mb-4">
                We invite you to support Heartfulness initiatives in alignment
                with Daaji&apos;s vision. By contributing an amount from a
                minimum of Rs 50 to any figure of your choice{" "}
                <b>on a recurring basis</b>, we can scale up the immense work
                that is happening all over the world through offering
                transformative meditation practices, wellness programs, and
                community outreach activities. Your regular support ensures the
                sustainability of these initiatives, enabling us to reach more
                individuals and communities in need.
              </p>
              <p className="mb-4">
                By supporting Heartfulness, you are helping to create a world
                where inner peace and collective well-being are within
                everyone&apos;s reach. Join us in this journey of nurturing
                hearts, creating a ripple effect of positivity and spiritual
                growth. Together, we can make a lasting impact and help manifest
                a brighter, more harmonious world for all.
              </p>
              <div className="data">
                <div sx={{ textAlign: "center", mt: ["2rem", "2rem", "5rem"] }}>
                  <h1
                    sx={{
                      fontWeight: 600,
                      marginBottom: "1rem",
                    }}
                  >
                    Areas Of Contribution
                  </h1>
                </div>
                <p className="text-center">
                  The four major domains into which Heartfulness has
                  successfully been making inroads for community wellbeing and
                  development are:{" "}
                </p>
                {data &&
                  data?.map((i, index) => (
                    <div
                      className="row"
                      key={i?.heading}
                      sx={{
                        display: "flex",
                        flexDirection: index % 2 === 0 ? "row-reverse" : "row",
                      }}
                    >
                      <div
                        className="col-lg-7 p-3"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div className="text-center">
                          <h2>{i?.heading}</h2>
                        </div>
                        <div
                          className="pt-2"
                          sx={{ fontSize: "18px !important" }}
                        >
                          <HtmlDiv htmlString={i?.desc} />
                        </div>
                      </div>
                      <div className="col-lg-5 d-flex justify-content-center align-items-center p-3">
                        <Image
                          src={i?.image}
                          sx={{
                            width: "95%",
                            height: "auto",
                            borderRadius: "10%",
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* <div
            className="my-5 py-5"
            sx={{
              alignCarouselItems: "center",
              justifyContent: "center",
              height: "auto",
              backgroundColor: "#eeeaea",
            }}
          >
            <h2 sx={{ fontWeight: "bold" }} className="text-center">
              Testimonials
            </h2>
            <ReactElasticCarousel breakPoints={breakPoints}>
              {data.map((value) => (
                <div
                  key={value.heading}
                  sx={{
                    minHeight: "250px",
                    color: "#000",
                    outline: "none",
                    margin: "10px",
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Image src={dummyImg} className="my-2 testImg" />
                  <h6 className="my-2 title">
                    <b>{value.heading}</b>
                  </h6>
                  <p className="desc">
                    <HtmlDiv htmlString={value.desc} />
                  </p>
                </div>
              ))}
            </ReactElasticCarousel>
          </div> */}
          {/* <div
            style={{
              backgroundImage: `url(${sliderBg})`,
              backgroundRepeat: "no-repeat",
              height: "200px",
              backgroundSize: "cover",
            }}
          >
            <EveryDropCountsSlider />
          </div> */}
          <div
            className="container"
            sx={{
              fontFamily:
                '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji',
            }}
          >
            <div
              id="form"
              sx={{
                mt: ["4em", "4em", "4em"],
                fontSize: ["16px", "16px", "28px"],
                fontWeight: "bolder",
                textAlign: "center",
                color: "#798d2c",
              }}
            >
              Come, join the making of this ocean of Heartfulness!
              <br />
              Contribute, as every drop counts!
            </div>
            <div className="col-12" id="donateNow">
              <div className="row d-flex justify-content-center py-5">
                <div
                  className="col-10 col-md-6"
                  sx={{
                    border: "1px solid grey",
                    borderRadius: "25px",
                    padding: "20px",
                  }}
                >
                  <div
                    sx={{
                      borderBottom: "1px solid black",
                      borderBottomStyle: "dashed",
                      fontSize: "18px",
                    }}
                  >
                    <h4 className="text-center">
                      <span sx={{ color: "black" }}>Small Contribution -</span>{" "}
                      <span sx={{ color: "#4d8f16" }}> Big Transformation</span>
                    </h4>
                    <p sx={{ fontSize: "14px" }} className="text-center">
                      Donating INR 50 every day adds up to INR 1500 per month
                      per family member (INR 50 x 30 days)
                    </p>
                  </div>
                  <h5
                    sx={{
                      color: "#4d8f16",
                      paddingTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    Monthly Recurring Donation
                  </h5>
                  <p sx={{ fontSize: "14px" }} className="text-center">
                    The amount specified by you below will be debited monthly.
                    You can always come back and close the plan. Happy Donating!
                  </p>
                  <p
                    sx={{
                      fontWeight: "500",
                      marginBottom: "0",
                      fontSize: "14px",
                    }}
                    className="py-3"
                  >
                    {" "}
                    Add Member{" "}
                    <small className="text-muted">
                      (Max of 10 members allowed)
                    </small>
                  </p>
                  {familyMembers &&
                    familyMembers?.map((member, index) => (
                      <div key={member?.index}>
                        <div>
                          <input
                            type="text"
                            value={member.value}
                            placeholder="Enter Name"
                            onChange={(event) =>
                              handleInputChange(member?.index, event)
                            }
                            sx={{
                              width: "67%",
                              marginBottom: "10px",
                              fontSize: "14px",
                            }}
                            className="inputFld"
                          />
                          {member?.index !== 1 && (
                            <span className="add addIcon">
                              <FontAwesomeIcon
                                icon="times"
                                onClick={() => removeMember(member?.index)}
                                sx={{
                                  fontSize: "1.5rem",
                                  verticalAlign: "middle",
                                  color: "red",
                                }}
                                title="Remove Member"
                              />
                            </span>
                          )}
                          {index === familyMembers?.length - 1 &&
                            familyMembers?.length !== 10 && (
                              <span
                                className={
                                  enable ? "disabled-div add" : "add addIcon"
                                }
                              >
                                <FontAwesomeIcon
                                  icon="user-plus"
                                  sx={{
                                    fontSize: "1.2rem",
                                    verticalAlign: "middle",
                                  }}
                                  onClick={handleAddField}
                                  title="Add Member"
                                />
                              </span>
                            )}
                        </div>
                        {member?.error && (
                          <small sx={{ color: "red" }}>{member?.error}</small>
                        )}
                      </div>
                    ))}
                  <p
                    sx={{
                      fontWeight: "500",
                      marginBottom: "0",
                      fontSize: "14px",
                    }}
                    className="py-3"
                  >
                    {" "}
                    Your Donation Amount{" "}
                    <span className="text-muted">(per person)</span>
                  </p>
                  <div className="text-center">
                    {defaultAmounts &&
                      defaultAmounts?.map((a) => (
                        <button
                          type="button"
                          onClick={() => {
                            setAmount(a);
                            setCustomAmount("");
                            setError("");
                          }}
                          sx={{
                            backgroundColor: `${amount === a ? "#4d8f16" : ""}`,
                            width: "20%",
                            margin: "1%",
                            border: `0.5px solid ${
                              amount === a ? "#4d8f16" : "grey"
                            }`,
                            color: `${amount === a ? "white" : "black"}`,
                            padding: "7px",
                            borderRadius: "6px",
                            fontSize: "12px",
                          }}
                        >
                          ₹{a}
                        </button>
                      ))}
                  </div>
                  <div>
                    <input
                      type="number"
                      inputMode="numeric"
                      value={customAmount}
                      placeholder="Enter Custom Amount"
                      className="my-3 inputFld"
                      onChange={(event) => {
                        const pattern = /^\d*[1-9]\d*$/;
                        if (!pattern.test(event?.target?.value)) {
                          if (!event?.target?.value) {
                            setAmount(50);
                            setError("");
                          } else {
                            setAmount("");
                            setError("Please enter a valid amount");
                          }
                          setCustomAmount(event?.target?.value);
                        } else {
                          setAmount("");
                          setError("");
                          setCustomAmount(event?.target?.value);
                        }
                      }}
                      sx={{
                        width: "100% !important",
                        marginBottom: "10px",
                        fontSize: "14px",
                      }}
                    />
                    {err && (
                      <p sx={{ color: "red", fontSize: "14px" }}>{err}</p>
                    )}
                  </div>
                  {errorMessage && <p className="errorMsg">{errorMessage}</p>}
                  <div className={`${flag ? "" : "disabled-div"}`}>
                    <PageContent
                      eventCallback={eventCallback}
                      pageContent={pageContent()}
                    />
                  </div>
                </div>
              </div>
              <p
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#63767a !important",
                  fontFamily:
                    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji',
                }}
              >
                For offline: Physical copies of the ECS donation form are
                available for download{" "}
                <button
                  type="button"
                  sx={{
                    color: "rgb(0, 97, 166)",
                    border: "none !important",
                    background: "none !important",
                    padding: "0 !important",
                    "&:hover": {
                      color: "rgb(40, 139, 208)",
                    },
                  }}
                  onClick={() => handleDownload()}
                >
                  here
                </button>
                .
              </p>
            </div>

            <p sx={{ fontSize: "15px", textAlign: "center", color: "#63767a" }}>
              You may email to us at{" "}
              <a href="mailto:info.accounts@sahajmarg.org">
                info.accounts@sahajmarg.org
              </a>{" "}
              or missed call (India) at +91-89395-89295 or Call toll free
              (india) at 1800-121-3492
            </p>
            <p className="mb-4 pb-4">&nbsp;</p>
          </div>
        </Section>
      </Content>
      <FooterComp />
      <EcsFormsDownload showDialog={showDialog} closeDialog={closeDialog} />
    </Layout>
  );
};

export default EveryDropCountsSmsf;
